import React from 'react';
import PropTypes from 'prop-types';
import Img from 'components/Image';
import { prefillSelector, providerById } from 'selectors/providers';
import classNames from 'classnames';

function SelectableIssue(props) {
  if (!props.issue.get) {
    return null;
  }

  const cover =
    props.issue._links.cover_image ||
    props.issue._links.page_preview ||
    (props.issue.get('_links') ? props.issue.get('_links').page_preview : undefined);

  if (!cover) {
    return null;
  }

  const width = (props.height / cover.height) * cover.width;

  const style = {
    width: Math.ceil(width),
    height: Math.ceil(props.height),
  };

  const manifest = props.issue.get('manifest');
  const providerId = manifest ? manifest.get('provider').id : props.issue.get('provider').id;
  const providerName = prefillSelector(providerById)(providerId).name;

  const classes = classNames('selectable-issue', {
    [props.className]: props.className,
  });

  const labelClasses = classNames(
    'v-selectable-issue',
    { selected: props.selected },
    { small: props.height === 115 },
  );

  return (
    <div className={classes}>
      <label aria-label={providerName} className={labelClasses} style={style}>
        <input
          type="checkbox"
          onChange={props.onChange}
          disabled={props.disabled}
          checked={props.selected}
        />
        <Img src={cover.href} alt={providerName} width={width} height={props.height} />
      </label>
    </div>
  );
}

SelectableIssue.propTypes = {
  issue: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  height: PropTypes.oneOf([115, 180]),
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default SelectableIssue;
