import { translateElement } from 'instances/i18n';

const React = require('react');
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const i18n = require('instances/i18n').locale;
const passwordStrengthMixin = require('components/mixins/passwordStrengthMixin');
const Input = require('components/Input');
const UserModel = require('models/user');
const formMixin = require('../mixins/formMixin');
const PrevPaneButton = require('../PrevPaneButton');

const AccountResetPassword = createReactClass({
  displayName: 'AccountResetPassword',
  mixins: [formMixin, passwordStrengthMixin],

  propTypes: {
    disabled: PropTypes.bool,
    token: PropTypes.string.isRequired,
    onPrev: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  },

  UNSAFE_componentWillMount() {
    this.isMounted = true;
  },

  componentWillUnmount() {
    this.isMounted = false;
  },

  changePassword(ev) {
    this.updatePasswordScore(ev.target.value || '');
    this.onMainFieldChange();
  },

  onResetSubmit(ev) {
    ev.preventDefault();

    this.setState({ error: null });

    // password has to be at least 5 chars long
    const modelExpressions = UserModel.prototype.expressions;
    if (!modelExpressions.password.test(this.getMainField().value)) {
      this.setState({
        error: 'error.password_too_short',
      });
      return null;
    }

    // also send the token as an argument to the onsubmit method
    return this.props.onSubmit(this.getMainField().value, this.props.token).catch(() => {
      if (!this.isMounted) return;
      this.setState({
        error: 'login.reset_token_invalid',
      });
    });
  },

  render() {
    const strengthColor = {
      color: this.getPasswordScoreColor(),
    };

    const error = this.state.error
      ? translateElement(<p className="input-error" />, `${this.state.error}`, false)
      : null;

    return (
      <form
        className="v-signup-account-password signup-single-form slide-animation"
        onSubmit={this.onResetSubmit}
      >
        <h2>{i18n.signup.resetPassword.title}</h2>
        <p>
          <Input
            className={this.getFieldClassNames()}
            style={strengthColor}
            ref="mainField"
            type="password"
            name="password"
            disabled={this.props.disabled}
            readOnly={this.props.disabled}
            autoComplete="new-password"
            placeholder={i18n.signup.resetPassword.placeholder}
            onChange={this.changePassword}
          />
        </p>
        {error}
        <Input
          type="submit"
          className="btn btn-submit"
          value={i18n.signup.resetPassword.submit}
          disabled={!this.state.navigateNext || this.props.disabled}
        />
        <PrevPaneButton onClick={this.props.onPrev} />
      </form>
    );
  },
});

module.exports = AccountResetPassword;
