import React from 'react';
import PropTypes from 'prop-types';

function Pointer(props) {
  const pointerStyle = {
    borderWidth: `${props.width}px`,
    borderStyle: 'solid',
    position: 'absolute',
    display: 'inline-block',
  };

  if (props.position === 'top') {
    pointerStyle.borderLeftColor = 'transparent';
    pointerStyle.borderRightColor = 'transparent';
    pointerStyle.borderTop = 'none';
  }

  if (props.position === 'bottom') {
    pointerStyle.borderLeftColor = 'transparent';
    pointerStyle.borderRightColor = 'transparent';
    pointerStyle.borderBottom = 'none';
    pointerStyle.borderTopWidth = '5px';
    pointerStyle.borderTopStyle = 'solid';
    pointerStyle.marginTop = 'none';
    pointerStyle.bottom = '4px';
  }

  if (props.position === 'bottom' || (props.position === 'top' && props.offset)) {
    if (props.direction === 'left') {
      pointerStyle.right = `${props.offset}px`;
    } else {
      pointerStyle.left = `${props.offset}px`;
    }
  }

  return <div className="v-pointer" style={pointerStyle} />;
}

Pointer.propTypes = {
  width: PropTypes.number.isRequired,
  position: PropTypes.string,
  direction: PropTypes.string,
  offset: PropTypes.number,
};

export default Pointer;
