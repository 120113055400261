const React = require('react');
const PropTypes = require('prop-types');
const i18n = require('instances/i18n').locale;
const Button = require('components/Button');

function SubmitPaneButton(props) {
  return (
    <div className="v-navigate-next">
      <Button
        type="submit"
        loading={props.loading}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        {props.value || i18n.signup.next}
      </Button>
      {props.children}
    </div>
  );
}

SubmitPaneButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  value: PropTypes.string,
  children: PropTypes.element,
};

SubmitPaneButton.defaultProps = {
  disabled: false,
  loading: false,
  value: null,
};

module.exports = SubmitPaneButton;
