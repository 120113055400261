const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

function SelectableChannelView(props) {
  const className = classNames({
    channel: true,
    selected: props.selected,
    [`channel-${props.channel.get('id')}`]: !props.selected,
    [`channel-${props.channel.get('id')}-bg`]: props.selected,
  });

  return (
    <label className={className}>
      <input
        type="checkbox"
        onChange={props.onChange}
        disabled={props.disabled}
        checked={props.selected}
      />
      {props.channel.get('username')}
    </label>
  );
}

SelectableChannelView.propTypes = {
  channel: PropTypes.object.isRequired,
};

module.exports = SelectableChannelView;
